<template>
  <div>
    <el-form
        ref="ruleFormRef"
        status-icon
        label-width="120px"
        class="demo-ruleForm"
    >
      <el-form-item label="First name" prop="pass">
        <el-input v-model="profile.first_name" type="text" autocomplete="off"/>
      </el-form-item>

      <el-form-item label="Last name" prop="pass">
        <el-input v-model="profile.last_name" type="text" autocomplete="off"/>
      </el-form-item>

      <el-form-item label="Location" prop="pass">
        <el-input v-model="profile.location" type="text" autocomplete="off"/>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm"
        >Update
        </el-button
        >
        <el-button @click="resetForm">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileUpdate',
  data() {
    return {
      profile: {
        first_name: '',
        last_name: '',
        location: '',
      },
    };
  },
  created() {
    this.profile = this.authUser;
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
  methods: {
    submitForm() {

    },
    resetForm() {
      this.profile = {};
    },
  },
};
</script>

<style scoped>

</style>
