<template>
  <div>
    <el-form
        ref="ruleFormRef"
        status-icon
        label-width="120px"
        class="demo-ruleForm"
    >
      <el-form-item label="Old Password" prop="pass">
        <el-input v-model="form.old_password" type="password" autocomplete="off"/>
      </el-form-item>

      <el-form-item label="New Password" prop="pass">
        <el-input v-model="form.new_password" type="password" autocomplete="off"/>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm"
        >Change Password
        </el-button
        >
        <el-button @click="resetForm">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ChangePassword',
  data() {
    return {
      form: {
        old_password: '',
        new_password: '',
      },
    };
  },
  methods: {
    submitForm() {
      axios.post('/api/v1/user/password/update', this.form).then((res) => {
        if (res.data.status_code == 400 || res.data.status_code == 500) {
          return this.$notify({
            title: 'Failed !!',
            message: res.data.message,
            type: 'error',
            duration: 2000,
          });
        }
        this.form = {};
        this.$notify({
          title: 'Success',
          message: 'Succesfully Password Changed',
          type: 'success',
          duration: 2000,
        });
      });
    },
    resetForm() {
      this.form = {};
    },
  },
};
</script>

<style scoped>

</style>
