<template>
  <el-card>
    <h3>Setting</h3>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="Edit Profile" name="edit_profile">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="10">
            <UpdateProfile/>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Change Password" name="change_password">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="10">
            <ChangePassword/>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import UpdateProfile from './ProfileUpdate';
import ChangePassword from './ChangePassword';

export default {
  name: 'Setting',
  components: {
    UpdateProfile,
    ChangePassword,
  },
  data() {
    return {
      activeName: 'edit_profile',
    };
  },
  methods: {
    handleClick() {

    },
  },
};
</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
